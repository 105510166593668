/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.15 public/tree_1.glb 
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'

export function Model(props) {
  const { nodes, materials } = useGLTF('/tree_1.glb')
  return (
    <group {...props} dispose={null}>
      <group position={[0.8, 0, 0.26]}>
        <mesh geometry={nodes.BezierCurve036.geometry} material={materials['trunk-01']} />
        <mesh geometry={nodes.BezierCurve036_1.geometry} material={materials['branch-1-01']} />
        <mesh geometry={nodes.BezierCurve036_2.geometry} material={materials['branch-1-02']} />
      </group>
    </group>
  )
}

useGLTF.preload('/tree_1.glb')
