import React, {
  useState,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useCallback,
} from "react";
import { useLoader, Canvas, useFrame, useThree } from "@react-three/fiber";
import { Model } from "../components/Tree_1";
import {
  XR,
  XRButton,
  Controllers,
  Interactive,
  Hands,
  RayGrab,
  useXR,
  InteractiveProps,
  XRManagerEvent,
  XREvent,
  XRControllerEvent,
  useController,
  useXREvent,
} from "@react-three/xr";
import {
  Color,
  Plane,
  Vector3,
  LinearFilter,
  CanvasTexture,
  Quaternion,
  Matrix4,
  Euler,
  ArrowHelper,
} from "three";
import { Link } from "react-router-dom";
import {
  OrbitControls,
  Environment,
  Sky,
  useVideoTexture,
  Html,
  Text,
  useTexture,
  StatsGl,
} from "@react-three/drei";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Slider from "@mui/material/Slider";
import { PerfHeadless, usePerf } from "r3f-perf";
import { Container, FormControlLabel, Switch, Typography } from "@mui/material";
// https://stackoverflow.com/questions/55371402/how-to-reference-pdf-js-library-in-react
// import pdfjsLib from "pdfjs-dist/build/pdf";
// import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";
import * as PDFJS from "react-pdf";
// import dSplat from '../public/3d_gaussian_splatting_low.pdf';

// import 'react-pdf/dist/Page/AnnotationLayer.css';
// import 'react-pdf/dist/Page/TextLayer.css';

PDFJS.pdfjs.GlobalWorkerOptions.workerSrc = "pdf.worker.min.js";
// new URL(
//   import.meta.url
// ).toString();

const showDevOptions = false;

// TODO: find out why there is just ever so little less margin on the right
const pdfAspectRatio = 3 / 4;
const defaultAutoRotate = false;
const logEnabled = false;
// const url = "./attention_is_all_you_need.pdf";
// const url = "./3d_gaussian_splatting_low.pdf";
const url = "./herman_melville_moby_dick.pdf";
// const url = './rust_book.pdf';
const hackYOffset = 0;

// meters per second to miles per hour
const mpsToMph = 2.23693629;
// speed is meters per second.
const initialSpeed = 2 / mpsToMph;

const snellenConfig = {
  // The texture is a power of two so that mipmaps can be generated.
  textureSizePx: 4096,
  // This is the valid part of the image.
  widthPx: 4000 * pdfAspectRatio,
  heightPx: 4000,

  x: 0,
  y: 0,
  z: 0, // 20/20 vision @ 20ft = 6.1m

  // This is the size of mesh and the visible part of the quad layer.
  widthMeters: 2.5 * pdfAspectRatio, // 320px image * (142mm/160px scale factor)
  heightMeters: 2.5, // 450px image * (142mm/160px scale factor)
  cropX: 0,
  cropY: 0,
  quadWidth: 0,
  quadHeight: 0,
};

snellenConfig.cropX = snellenConfig.widthPx / snellenConfig.textureSizePx;
snellenConfig.cropY = snellenConfig.heightPx / snellenConfig.textureSizePx;

snellenConfig.quadWidth =
  (0.5 * snellenConfig.widthMeters) / snellenConfig.cropX;
snellenConfig.quadHeight =
  (0.5 * snellenConfig.heightMeters) / snellenConfig.cropY;

interface SpeedControlProps {
  scale: number;
  position: Vector3;
  speed: number;
  setSpeed: any;
}

function SpeedControl(props: SpeedControlProps) {
  const speedIncrement = 0.1 / mpsToMph;
  const up_gltf = useLoader(GLTFLoader, "/up.glb");
  const down_gltf = useLoader(GLTFLoader, "/down.glb");
  const upRef = useRef<any>(null!);
  const downRef = useRef<any>(null!);
  return (
    <>
      <Text color="black" scale={props.scale} position={props.position}>
        {(props.speed * mpsToMph).toFixed(2)}
      </Text>
      <Interactive
        onSelect={() =>
          props.setSpeed((prevSpeed: number) => prevSpeed + speedIncrement)
        }
      >
        <mesh
          ref={upRef}
          onClick={() =>
            props.setSpeed((prevSpeed: number) => prevSpeed + speedIncrement)
          }
        >
          <primitive
            object={up_gltf.scene}
            scale={0.1}
            position={new Vector3(0.0, 0.15, 0).add(props.position.clone())}
            rotation={[3.1415 / 2, 3.1415 / 2, 0]}
          />
        </mesh>
      </Interactive>
      <Interactive
        onSelect={() =>
          props.setSpeed((prevSpeed: number) => prevSpeed - speedIncrement)
        }
      >
        <mesh
          ref={downRef}
          onClick={() =>
            props.setSpeed((prevSpeed: number) => prevSpeed - speedIncrement)
          }
        >
          <primitive
            object={down_gltf.scene}
            scale={0.1}
            position={new Vector3(0.0, -0.15, 0).add(props.position.clone())}
            rotation={[3.1415 / 2, -3.14145 / 2, 0]}
          />
        </mesh>
      </Interactive>
    </>
  );
}

interface PerfHookProps {
  scale: number;
  position: Vector3;
}

// TODO: find a better library to use for perf, this one sucks.
// It causes rerenders and logs that it breaks the rules of hooks.
function PerfHook(props: PerfHookProps) {
  // TODO: remove the need to use r3f-perf
  // getPerf() is also available for non-reactive way
  const textARef = useRef<any>(null!);
  const textBRef = useRef<any>(null!);
  const [log, getReport]: any = usePerf((s: any) => [s.log, s.getReport]);
  const report = getReport();
  // if (Math.random() > 0.95) {
  //   console.log(log, getReport())
  // }
  const offsetPosition = new Vector3(0, -0.1, 0);
  return (
    <>
      <Text
        ref={textARef}
        color={"black"}
        scale={0.1}
        position={props.position.clone()}
        rotation={[0, 3.1415, 0]}
      >
        FPS: {log && log.fps.toFixed(2)}
      </Text>
      <Text
        ref={textBRef}
        color={"black"}
        scale={0.1}
        position={props.position.clone().addScaledVector(offsetPosition, 1)}
        rotation={[0, 3.1415, 0]}
      >
        Triangles: {report.gl.triangles.toFixed(1)}
      </Text>
      <PerfHeadless />
    </>
  );
}

interface BasicBackgroundForestProps {
  scale: number;
  position: Vector3;
  speed: number;
}

function BasicBackgroundForest(props: BasicBackgroundForestProps) {
  const grass_gltf = useLoader(GLTFLoader, "/flat_grass.glb");
  // console.log({grass_gltf});
  const grass_nodes: any = grass_gltf.nodes.tile_for_home_1x1002;

  const simple_tree_gltf = useLoader(GLTFLoader, "/simple_tree.glb");
  // const trees_gltf = useLoader(GLTFLoader, "/tree_1.glb");
  const tree_nodes: any = simple_tree_gltf.nodes.trees001;
  // console.log({nodes: trees_gltf.nodes});
  // console.log({materials: trees_gltf.materials});
  // const tree_nodes: any = trees_gltf.scene;
  // console.log({tree: tree_nodes});
  const simple_small_tree_gltf = useLoader(
    GLTFLoader,
    "/simple_small_tree.glb"
  );
  const grassARef = useRef<any>(null!);
  const grassBRef = useRef<any>(null!);
  const treeARef = useRef<any>(null!);
  const treeBRef = useRef<any>(null!);
  const treeCRef = useRef<any>(null!);
  const treeDRef = useRef<any>(null!);
  const treeERef = useRef<any>(null!);
  const treeFRef = useRef<any>(null!);
  const treeGRef = useRef<any>(null!);

  useFrame((state, delta) => {
    const offset = delta * props.speed;
    treeARef.current.position.z += offset;
    treeBRef.current.position.z += offset;
    treeCRef.current.position.z += offset;
    treeDRef.current.position.z += offset;
    treeERef.current.position.z += offset;
    treeFRef.current.position.z += offset;
    treeGRef.current.position.z += offset;

    if (treeARef.current.position.z > 2) {
      treeARef.current.position.z = -12;
    }
    if (treeBRef.current.position.z > 2) {
      treeBRef.current.position.z = -12;
    }
    if (treeCRef.current.position.z > 2) {
      treeCRef.current.position.z = -12;
    }
    if (treeDRef.current.position.z > 2) {
      treeDRef.current.position.z = -12;
    }
    if (treeERef.current.position.z > 2) {
      treeERef.current.position.z = -12;
    }
    if (treeFRef.current.position.z > 2) {
      treeFRef.current.position.z = -12;
    }
    if (treeGRef.current.position.z > 2) {
      treeGRef.current.position.z = -12;
    }
  });
  return (
    <>
      <mesh
        ref={grassARef}
        material={grass_gltf.materials.City_low_poly}
        geometry={grass_nodes.geometry}
        scale={1}
        position={new Vector3(0, 0, -6).add(props.position.clone())}
        rotation={[0, 0, 0]}
      />
      <mesh
        ref={grassBRef}
        material={grass_gltf.materials.City_low_poly}
        geometry={grass_nodes.geometry}
        scale={1}
        position={new Vector3(0, 0, -3).add(props.position.clone())}
        rotation={[0, 0, 0]}
      />

      <group
        ref={treeARef}
        position={new Vector3(-4, 0, 0).add(props.position.clone())}>
        <Model />
      </group>
      <group
        ref={treeBRef}
        position={new Vector3(3, 0, -3).add(props.position.clone())}>
        <Model />
      </group>
      <group
        ref={treeCRef}
        position={new Vector3(3.5, 0, -1).add(props.position.clone())}>
        <Model />
      </group>
      <group
        ref={treeDRef}
        position={new Vector3(-4.5, 0, 2).add(props.position.clone())}>
        <Model />
      </group>
      <group
        ref={treeERef}
        position={new Vector3(-4.5, 0, 2).add(props.position.clone())}>
        <Model />
      </group>
      <group
        ref={treeFRef}
        position={new Vector3(-8.5, 0, 2).add(props.position.clone())}>
        <Model />
      </group>
      <group
        ref={treeGRef}
        position={new Vector3(5.5, 0, 2).add(props.position.clone())}>
        <Model />
      </group>
    </>
  );
}

interface PdfViewerProps {
  scale: number;
  position: Vector3;
  pdfRef: any;
}

function PdfViewer(props: PdfViewerProps) {
  // const {session} = useXR();
  const { gl } = useThree();
  const xr: any = gl.xr;
  const [quadLayer, setQuadLayer] = useState<any>(null);
  const glContext = gl.getContext();
  const session = xr.getSession();
  const play_gltf = useLoader(GLTFLoader, "/play_button.glb");
  const play_copy_gltf = useLoader(GLTFLoader, "/play_button_copy.glb");
  // const pause_gltf = useLoader(GLTFLoader, '/pause_button.glb')
  const meshNextRef = useRef<any>(null!);
  const meshPrevRef = useRef<any>(null!);
  const meshRef = useRef<any>(null!);
  const grabRef = useRef<any>(null!);
  const textureRef = useRef<any>(null!);
  const materialRef = useRef<any>(null!);
  const [logLines, setLogLines] = useState<Array<string>>([]);
  // TODO: wrap setLogLines in enable check for perf
  const [playing, setPlaying] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [ref, setRef] = useState(false);
  const pageRef = useRef<any>(null!);
  const [pdfTexture, setPdfTexture] = useState<any>();
  // const texture = useVideoTexture("bad_apple.mp4", { muted: false, start: playing});

  // const xrFramebuffer = gl.createFramebuffer();
  // TODO: remove the callback usage, this is dumb
  const drawPdfQuad = useCallback(
    (ses: any, canRef: any, xr: any, mRef: any) => {
      if (ses !== null && canRef.current !== null && mRef.current !== null) {
        // TODO: had to make this local-floor, I need to read the docs
        ses.requestReferenceSpace("local-floor").then((refSpace: any) => {
          // const gl = canvasRef.current.getContext('wegbl', { xrCompatible: true, webgl2: true, });
          // setLogLines(lines => lines.concat(`layer count ${session.renderState.layers.length}`))
          // setLogLines(lines => lines.concat('pre layer render'))

          const glBinding = xr.getBinding();
          // const xrGlBinding = new XRWebGLBinding(session as unknown as XRSession, gl);
          // const projectionLayer = new XRWebGLLayer(session as unknown as XRSession, gl);
          const position = {
            x: mRef.current.position.x,
            y: mRef.current.position.y,
            z: mRef.current.position.z,
          };
          const orientation = {
            x: mRef.current.quaternion.x,
            y: mRef.current.quaternion.y,
            z: mRef.current.quaternion.z,
            w: mRef.current.quaternion.w,
          };
          // setLogLines(lines => lines.concat(`orien: ${JSON.stringify(orientation)}`))
          // setLogLines(lines => lines.concat(`orien 2: ${JSON.stringify(meshRef.current.quaternion)}`))
          const quat = new Quaternion(0, 0, 0, 1);
          mRef.current.getWorldQuaternion(quat);
          // setLogLines(lines => lines.concat(`orien 3: ${JSON.stringify(quat)}`))
          const pos = new Vector3(0, 0, 0);
          mRef.current.getWorldPosition(pos);
          // TODO: remove the need to move this down by 2
          pos.setY(pos.y - hackYOffset);
          const quadLayerConfig = {
            width: snellenConfig.quadWidth,
            height: snellenConfig.quadHeight,
            viewPixelWidth: snellenConfig.textureSizePx,
            viewPixelHeight: snellenConfig.textureSizePx,
            isStatic: true,
            space: refSpace,
            layout: "mono",
            transform: new XRRigidTransform(pos, quat),
          };
          const quadLayerTemp = glBinding.createQuadLayer(quadLayerConfig);
          setLogLines((lines) =>
            lines.concat(`orien 3: ${JSON.stringify(quadLayerTemp)}`)
          );
          // const quadLayer = glBinding.createQuadLayer({
          //   viewPixelWidth: 4024,
          //   viewPixelHeight: 4024,
          //   space: refSpace,
          // })

          setQuadLayer(quadLayerTemp);
          const renderState: XRRenderState = {
            depthFar: 1000,
            depthNear: 0.1,
            layers: [ses.renderState.layers[0], quadLayerTemp],
          };

          ses.updateRenderState(renderState);
          // quadLayer.needsUpdate = true
          setLogLines((lines) => lines.concat("post layer render"));
        });
      }
    },
    []
  );

  useEffect(() => {
    drawPdfQuad(session, canvasRef, xr, meshRef);
    // drawPdfQuad();
  }, [session, pdfTexture]);

  useFrame((state, delta, xrFrame) => {
    if (session !== null && quadLayer !== null) {
      // setLogLines(lines => lines.concat('quad basics check'))
      if (quadLayer.needsRedraw) {
        // setLogLines(lines => lines.concat('needs redraw quadLayer'))
        let glayer = xr.getBinding().getSubImage(quadLayer, xrFrame);

        // TEXTURE_CUBE_MAP expects the Y to be flipped for the faces and it already
        // is flipped in our texture image.
        glContext.pixelStorei(glContext.UNPACK_FLIP_Y_WEBGL, true);
        glContext.bindTexture(glContext.TEXTURE_2D, glayer.colorTexture);
        glContext.texSubImage2D(
          glContext.TEXTURE_2D,
          0,
          (snellenConfig.textureSizePx - snellenConfig.widthPx) / 2,
          (snellenConfig.textureSizePx - snellenConfig.heightPx) / 2,
          snellenConfig.widthPx,
          snellenConfig.heightPx,
          glContext.RGBA,
          glContext.UNSIGNED_BYTE,
          pdfTexture.image
        );
      }
    }
  });
  // useEffect(() => {
  //   if (props.pdfRef.current !== null) {
  //     setRef(props.pdfRef.current);
  //     console.log('set ref')
  //   }
  // }, [props.pdfRef]);
  // useLayoutEffect(() => {
  //   if (textureRef.current !== null) {
  //     textureRef.current.needsUpdate = true;
  //     console.log({textureRef})
  //   }
  //   if (materialRef.current !== null) {
  //     // materialRef.current.needsUpdate = true;
  //     // console.log({materialRef})
  //   }
  //   textureRef.current.needsUpdate = true;
  // }, [pageNumber]);

  const handleSelect = () => {
    // texture.image.pause();
    // !!!!!!!!!!
    // It was critical to have the state change for the pdf texture to update.
    // TODO: simplify this.
    setPlaying((state) => !state);
    if (textureRef.current !== null) {
      textureRef.current.needsUpdate = true;
    }
  };
  const handleNextSelect = () => {
    // texture.image.pause();
    // setPageNumber(num => num + 1);
    setLogLines((lines) => lines.concat("next"));
    props.pdfRef &&
      currentPage < props.pdfRef.numPages &&
      setCurrentPage(currentPage + 1);
    if (textureRef.current !== null) {
      textureRef.current.needsUpdate = true;
    }
  };
  const handlePrevSelect = () => {
    // texture.image.pause();
    // setPageNumber(num => num == 1 ? num : num - 1);
    setLogLines((lines) => lines.concat("prev"));
    currentPage > 1 && setCurrentPage(currentPage - 1);
    if (textureRef.current !== null) {
      textureRef.current.needsUpdate = true;
    }
  };
  //   if (props.pdfRef.current !== null) {
  // setRef(props.pdfRef.current);
  // }
  // console.log({what: props.pdfRef});
  // console.log({ref});

  // const canvasRef = useRef<any>(null!)
  const canvasRef = useRef<any>(
    new OffscreenCanvas(4000 * pdfAspectRatio, 4000)
  );
  // pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;

  const [currentPage, setCurrentPage] = useState(1);

  const renderPage = useCallback(
    (pageNum: number, pdf: any = props.pdfRef) => {
      const canvas = new OffscreenCanvas(4000 * pdfAspectRatio, 4000);
      pdf &&
        pdf.getPage(pageNum).then(function (page: any) {
          if (canvasRef.current != null) {
            const viewport = page.getViewport({ scale: 5 });
            // const canvas = canvasRef.current;
            // canvas.height = viewport.height;
            // canvas.width = viewport.width;
            const renderContext = {
              canvasContext: canvas.getContext("2d"),
              viewport: viewport,
              intent: "print",
            };
            // console.log({page});
            setLogLines((lines) => lines.concat("pre render"));
            const renderTaskPromise = page.render(renderContext).promise;
            // console.log({prom: renderTaskPromise});
            setLogLines((lines) => lines.concat("mid render"));
            renderTaskPromise.then(() => {
              setLogLines((lines) => lines.concat("post render"));
              setTimeout(() => {
                setPlaying((state) => !state);
                if (textureRef.current !== null) {
                  textureRef.current.needsUpdate = true;
                }
                canvasRef.current.getContext("2d").drawImage(canvas, 0, 0);
                setPdfTexture(
                  new CanvasTexture(
                    canvasRef.current,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    LinearFilter
                  )
                );
                // TODO: I have to turn off canvasRef needs update on quest sometimes.
                // canvasRef.current.needsUpdate = true;
                // TODO: Find out why I need to set this to 2000 or so on a quest headset.
                // TODO: remove this delay, it might not be needed anymore.
              }, 0);
              // setPlaying(state => !state);
              // textureRef.current.needsUpdate = true;
            });
            setLogLines((lines) => lines.concat("render"));
          } else {
            setLogLines((lines) => lines.concat("canvas null in render"));
          }
        });
      // HACK to get page to render
    },
    [props.pdfRef]
  );

  useEffect(() => {
    renderPage(currentPage, props.pdfRef);
  }, [props.pdfRef, currentPage, renderPage]);

  // console.log({can: canvasRef.current});
  // console.log({text: textureRef.current});

  const startPosition = new Vector3(0, 0, 0);
  const offsetPosition = new Vector3(0, 0.1, 0);
  const renderedLogLines = logLines.map((line, index) => {
    return (
      <Text
        key={index}
        color={"black"}
        scale={0.1}
        position={startPosition.clone().addScaledVector(offsetPosition, index)}
      >
        {line}
      </Text>
    );
  });

  const handleMove = () => {
    // drawPdfQuad();
    // drawPdfQuad(session, canvasRef, xr, groupRef, meshRef);
    const position = {
      x: meshRef.current.position.x,
      y: meshRef.current.position.y,
      z: meshRef.current.position.z,
    };
    const orientation = {
      x: meshRef.current.quaternion.x,
      y: meshRef.current.quaternion.y,
      z: meshRef.current.quaternion.z,
      w: meshRef.current.quaternion.w,
    };
    // setLogLines(lines => lines.concat(`orien: ${JSON.stringify(orientation)}`))
    // setLogLines(lines => lines.concat(`orien 2: ${JSON.stringify(meshRef.current.quaternion)}`))
    const quat = new Quaternion(0, 0, 0, 1);
    meshRef.current.getWorldQuaternion(quat);
    // setLogLines(lines => lines.concat(`orien 3: ${JSON.stringify(quat)}`))
    const pos = new Vector3(0, 0, 0);
    meshRef.current.getWorldPosition(pos);
    // TODO: remove the need to move this down by 2
    pos.setY(pos.y - hackYOffset);
    if (quadLayer !== null) {
      quadLayer.transform = new XRRigidTransform(pos, quat);
      quadLayer.needsUpdate = true;
      //  setLogLines(lines => lines.concat('select End redraw quad'))
      //  setLogLines(lines => lines.concat(`orien 2: ${JSON.stringify(quadLayer)}`))
    }
  };

  const leftButtonOffset = new Vector3(1.2, 0, 0);
  const rightButtonOffset = new Vector3(-1.2, 0, 0);
  const grabbingController = React.useRef<THREE.Object3D>();
  const groupRef = React.useRef<THREE.Group>(null!);
  const previousTransform = useMemo(() => new Matrix4(), []);

  useFrame(() => {
    const controller = grabbingController.current;
    const group = groupRef.current;
    if (!controller) return;

    group.applyMatrix4(previousTransform);
    group.applyMatrix4(controller.matrixWorld);
    group.updateMatrixWorld();

    previousTransform.copy(controller.matrixWorld).invert();

    // Move the layer when moving pdf
    handleMove();

    // Move the UI elements when moving pdf
    // TODO:
  });

  // <canvasTexture ref={textureRef} minFilter={LinearFilter} attach="map" image={pdfTexture} />
  // <Html>
  // <canvas ref={canvasRef} height={4000} width='4000'/>
  // </Html>
  const handleRightScaleUp = () => {
    meshNextRef.current.scale.set(1.2, 1.2, 1.2);
    meshNextRef.current.position.add(new Vector3(-0.2, -0.3, 0.3));
  };
  const handleRightScaleDown = () => {
    meshNextRef.current.scale.set(1.0, 1.0, 1.0);
    meshNextRef.current.position.add(new Vector3(0.2, 0.3, -0.3));
  };
  const handleLeftScaleUp = () => {
    meshPrevRef.current.scale.set(1.2, 1.2, 1.2);
    meshPrevRef.current.position.add(new Vector3(0.2, -0.3, 0.3));
  };
  const handleLeftScaleDown = () => {
    meshPrevRef.current.scale.set(1.0, 1.0, 1.0);
    meshPrevRef.current.position.add(new Vector3(-0.2, 0.3, -0.3));
  };
  return (
    <>
      {logEnabled && renderedLogLines}
      <Interactive
        onSelect={() => handleNextSelect()}
        onHover={handleRightScaleUp}
        onBlur={handleRightScaleDown}
      >
        <mesh
          ref={meshNextRef}
          onClick={() => handleNextSelect()}
          onPointerOver={handleRightScaleUp}
          onPointerOut={handleRightScaleDown}
        >
          <primitive
            object={play_gltf.scene}
            scale={0.3}
            position={props.position.clone().add(leftButtonOffset)}
            rotation={[Math.PI / 2, 0, 0]}
          />
          <meshBasicMaterial color={"yellow"} />
        </mesh>
      </Interactive>
      <Interactive
        onSelect={() => handlePrevSelect()}
        onHover={handleLeftScaleUp}
        onBlur={handleLeftScaleDown}
      >
        <mesh
          ref={meshPrevRef}
          onClick={() => handlePrevSelect()}
          onPointerOver={handleLeftScaleUp}
          onPointerOut={handleLeftScaleDown}
        >
          <primitive
            object={play_copy_gltf.scene}
            scale={0.3}
            position={props.position.clone().add(rightButtonOffset)}
            rotation={[Math.PI / 2, Math.PI / 3, 0]}
          />
        </mesh>
      </Interactive>
      {pdfTexture && (
        <Interactive
          ref={groupRef}
          onSelectStart={(e) => {
            grabbingController.current = e.target.controller;
            previousTransform.copy(e.target.controller.matrixWorld).invert();
          }}
          onSelectEnd={(e) => {
            if (e.target.controller === grabbingController.current) {
              grabbingController.current = undefined;
            }
            handleMove();
          }}
        >
          <mesh
            ref={meshRef}
            scale={props.scale}
            position={props.position}
            onClick={() => handleSelect()}
          >
            <planeGeometry args={[1 * pdfAspectRatio, 1]} />
            <meshBasicMaterial ref={materialRef} map={pdfTexture} />
          </mesh>
        </Interactive>
      )}
    </>
  );
}

interface DoubleRayGrabProps extends InteractiveProps {
  setLogLines: any;
}
const DoubleRayGrab = React.forwardRef<THREE.Group, DoubleRayGrabProps>(
  function DoubleRayGrab(
    { onSelectStart, onSelectEnd, setLogLines, children, ...rest },
    forwardedRef
  ) {
    // TODO: fix this as it is busted as heck. I think the issue is from translation of the scene.
    // This must assume things are centered.
    const grabbingController = React.useRef<THREE.Object3D>();
    const groupRef = React.useRef<THREE.Group>(null!);
    const previousTransform = React.useMemo(() => new Matrix4(), []);
    React.useImperativeHandle(forwardedRef, () => groupRef.current);

    useFrame(() => {
      const controller = grabbingController.current;
      const group = groupRef.current;
      if (!controller) return;

      group.applyMatrix4(previousTransform);
      group.applyMatrix4(controller.matrixWorld);
      group.updateMatrixWorld();

      previousTransform.copy(controller.matrixWorld).invert();
    });

    return (
      <Interactive
        ref={groupRef}
        onSelectStart={(e) => {
          grabbingController.current = e.target.controller;
          previousTransform.copy(e.target.controller.matrixWorld).invert();
          onSelectStart?.(e);
        }}
        onSelectEnd={(e) => {
          if (e.target.controller === grabbingController.current) {
            grabbingController.current = undefined;
          }
          onSelectEnd?.(e);
        }}
        {...rest}
      >
        {children}
      </Interactive>
    );
  }
);

interface WalkingAreaProps {
  pdfRef: any;
  showControls: boolean;
  position: Vector3;
  quaternion: Quaternion;
}

function WalkingArea(props: WalkingAreaProps) {
  // <fog attach="fog" color={new Color(0, 0, 0)} near={1} far={10} />
  const [speed, setSpeed] = useState<number>(initialSpeed);
  const [sky, setSky] = useState<boolean>(false);
  // TODO: fix hands rendering gettin stuck when perf is turned off.
  const [perf, setPerf] = useState<boolean>(false);

  return (
    <>
      <group position={props.position} quaternion={props.quaternion}>
        {props.pdfRef && (
          <PdfViewer
            scale={2}
            position={new Vector3(0, 1.7, -1.6)}
            pdfRef={props.pdfRef}
          />
        )}

        {props.showControls && (
          <Text
            scale={0.1}
            color="black"
            position={new Vector3(0, 1, 2)}
            rotation={[0, 3.1415, 0]}
            onClick={() => setSky((p) => !p)}
          >
            Toggle Sky
          </Text>
        )}
        {props.showControls && (
          <Interactive onSelect={() => setSky((p) => !p)}>
            <mesh
              scale={0.1}
              position={new Vector3(0, 1, 2.1)}
              rotation={[0, 3.1415, 0]}
            >
              <boxGeometry args={[8, 2, 1]} />
              <meshBasicMaterial color="white" />
            </mesh>
          </Interactive>
        )}
        {sky && <Sky />}

        {showDevOptions && props.showControls && (
          <Text
            scale={0.1}
            color="black"
            position={new Vector3(0.9, 1.5, 2)}
            rotation={[0, 3.1415, 0]}
            onClick={() => setPerf((p) => !p)}
          >
            Toggle Perf
          </Text>
        )}
        {showDevOptions && props.showControls && (
          <Interactive onSelect={() => setPerf((p) => !p)}>
            <mesh
              scale={0.1}
              position={new Vector3(0.9, 1.5, 2.1)}
              rotation={[0, 3.1415, 0]}
            >
              <boxGeometry args={[8, 2, 1]} />
              <meshBasicMaterial color="white" />
            </mesh>
          </Interactive>
        )}
        {showDevOptions && perf && (
          <PerfHook scale={1} position={new Vector3(0, 1.5, 2)} />
        )}

        <SpeedControl
          scale={0.1}
          position={new Vector3(-0.5, 1, -0.5)}
          speed={speed}
          setSpeed={setSpeed}
        />
        <BasicBackgroundForest
          scale={10}
          position={new Vector3(0, 0, 0)}
          speed={speed}
        />
      </group>
    </>
  );
}

interface SetupWalkingProps {
  setPosition: any;
  setQuaternion: any;
  setSetupComplete: any;
}

function SetupWalking(props: SetupWalkingProps) {
  const leftController = useController("left");
  const rightController = useController("right");
  const sphereRef = useRef<any>(null!);
  const coneRef = useRef<any>(null!);
  const eulerRef = useRef<Euler>(new Euler());
  const quatRef = useRef<Quaternion>(new Quaternion());
  const [step, setStep] = useState<number>(1);
  const [stepOneComplete, setStepOneComplete] = useState<boolean>(false);
  const [stepTwoComplete, setStepTwoComplete] = useState<boolean>(false);
  const { camera } = useThree();
  const [debug, setDebug] = useState<string>("");

  useFrame((state, _, xrFrame) => {
    if (Math.random() > 0.5) {
      const refSpace = state.gl.xr.getReferenceSpace();
      if (refSpace !== null && xrFrame !== undefined) {
        const pose = xrFrame.getViewerPose(refSpace);
        const orientation = pose?.transform.orientation;
        const quat = new Quaternion(
          0,
          orientation?.y,
          0,
          orientation?.w
        ).normalize();
        quatRef.current = quat;
      }
    }
  });

  useFrame(() => {
    if (
      step === 1 &&
      rightController !== null &&
      rightController !== undefined &&
      rightController.controller !== null
    ) {
      const pos = rightController.controller.position.clone();
      sphereRef.current.position.x = pos.x;
      sphereRef.current.position.y = pos.y;
      sphereRef.current.position.z = pos.z;
    }
  });

  const callbackOne = useCallback(
    (event: XREvent<XRControllerEvent>) => {
      if (
        step === 1 &&
        rightController !== null &&
        rightController !== undefined &&
        rightController.controller !== null
      ) {
        const pos = rightController.controller.position.clone();
        setStep(2);
        props.setPosition(new Vector3(pos.x, 0, pos.z));
      }
    },
    [rightController, step]
  );
  useXREvent("select", callbackOne);
  const callbackTwo = useCallback(
    (event: XREvent<XRControllerEvent>) => {
      if (step === 2) {
        props.setQuaternion(quatRef.current.clone());
        props.setSetupComplete(true);
      }
    },
    [camera, step]
  );
  useXREvent("select", callbackTwo);
  return (
    <>
      {step === 1 && (
        <>
          <mesh receiveShadow ref={sphereRef}>
            <sphereGeometry args={[0.01, 32, 16]} />
            <meshBasicMaterial color={"white"} />
          </mesh>
          <Text color="white" scale={0.1} position={[0, 1.6, -1]}>
            Place the sphere in your right hand
          </Text>
          <Text color="white" scale={0.1} position={[0, 1.5, -1]}>
            in the center of your walking spot and pinch.
          </Text>
        </>
      )}
      {step === 2 && (
        <>
          <Text color="white" scale={0.1} position={[0, 1.6, -1]}>
            Face forward and pinch
          </Text>
        </>
      )}
    </>
  );
}

interface WalkingXRProps {
  pdfRef: any;
}

function WalkingXR(props: WalkingXRProps) {
  // When the xr session starts hide the default positioned environment, user space setup needed.
  // When the xr session ends, set the camera and environment back to the default spot so the ui looks right.
  // TODO: make sure the page and position don't get lost on exit.
  const defaultCameraPosition = new Vector3(0, 0, 5);
  const defaultCameraQuaternion = new Quaternion(0, 0, 0, 0);
  const [setupComplete, setSetupComplete] = useState<boolean>(false);
  const [inSession, setInSession] = useState<boolean>(false);
  const defaultPosition = new Vector3(0, 0, 0);
  const [position, setPosition] = useState<Vector3>(defaultPosition);
  const defaultQuaternion = new Quaternion(0, 0, 0, 0);
  const [quaternion, setQuaternion] = useState<Quaternion>(defaultQuaternion);
  const { camera } = useThree();

  const handleSessionStart = (event: XREvent<XRManagerEvent>) => {
    setInSession(true);
  };
  const handleSessionEnd = (event: XREvent<XRManagerEvent>) => {
    setInSession(false);
    camera.position.copy(defaultCameraPosition);
    camera.quaternion.copy(defaultCameraQuaternion);
  };

  return (
    <XR onSessionStart={handleSessionStart} onSessionEnd={handleSessionEnd}>
      <Controllers />
      <ambientLight intensity={0.1} />
      <pointLight position={[10, 10, 10]} intensity={0.2} />
      <pointLight position={[0, 1, 0]} intensity={0.2} />
      {!setupComplete && inSession && (
        <SetupWalking
          setPosition={setPosition}
          setQuaternion={setQuaternion}
          setSetupComplete={setSetupComplete}
        />
      )}

      {(setupComplete || !inSession) && (
        <WalkingArea
          pdfRef={props.pdfRef}
          showControls={inSession}
          position={inSession ? position : defaultPosition}
          quaternion={inSession ? quaternion : defaultQuaternion}
        />
      )}
      <Hands />
    </XR>
  );
}

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

// TODO: fix this as this approach doesn't handle the ar not supported text showing on the button.
const VisuallyHiddenXRButton = styled(XRButton)({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

export default function WalkingTest() {
  const [pdfRef, setPdfRef] = useState<any>();

  // Handles new pdf being loaded
  const handleOnChange = (event: React.FormEvent<HTMLInputElement>) => {
    if (event.currentTarget.files === null) {
      console.log("no files");
    } else {
      const file = event.currentTarget.files[0];

      const fileReader = new FileReader();

      fileReader.onload = (e) => {
        if (e !== null && e.target !== null && e.target.result !== null) {
          let typedarray;
          if ("string" === typeof e.target.result) {
            typedarray = e.target.result;
          } else {
            typedarray = new Uint8Array(e.target.result);
          }

          //replaced the old function with the new api
          const loadingTask = PDFJS.pdfjs.getDocument(typedarray);
          loadingTask.promise.then((loadedPdf) => {
            setPdfRef(loadedPdf);
            // console.log('loaded');
            // The document is loaded here...
          });
        }
      };
      //Step 3:Read the file as ArrayBuffer
      fileReader.readAsArrayBuffer(file);
    }
  };

  // Handles intial load
  useEffect(() => {
    const loadingTask = PDFJS.pdfjs.getDocument({
      url: url,
      isOffscreenCanvasSupported: true,
    });
    loadingTask.promise.then(
      (loadedPdf) => {
        setPdfRef(loadedPdf);
      },
      function (reason) {
        console.error(reason);
      }
    );
  }, [url]);
  const [arSupported, setArSupported] = useState<boolean>(false);
  const [vrSupported, setVrSupported] = useState<boolean>(false);
  useEffect(() => {
    navigator.xr?.isSessionSupported("immersive-ar").then((supported) => {
      setArSupported(supported);
    });
    navigator.xr?.isSessionSupported("immersive-vr").then((supported) => {
      setVrSupported(supported);
    });
  }, []);

  const anyXrSupported = arSupported || vrSupported;
  const xrMode = arSupported ? "AR" : "VR";
  return (
    <>
      <Grid container alignItems="flex-start" spacing={2}>
        <Grid item xs={12} md={6}>
          <Typography fontSize="1.5rem">
            <p>
              This website allows you to read PDFs in mixed reality. You can
              read while stationary, walking on a treadmill, riding a stationary
              bike, using a rowing machine, or whatever.
            </p>
            <p>
              Tim Berners-Lee's{" "}
              <a href="https://info.cern.ch/hypertext/WWW/TheProject.html">
                vision
              </a>{" "}
              for the web was "... to give universal access to a large universe
              of documents." I hope this website encourages reading of more
              documents.
            </p>
            <p>
              Your data is private, but don't put sensitive documents into
              random websites.
            </p>
          </Typography>
        </Grid>
        <Grid container item xs={12} md={6}>
          <Grid container item direction="column" spacing={2} xs={12}>
            <Grid item xs>
              <Button
                sx={{ marginTop: "24px" }}
                component="label"
                variant="contained"
              >
                Select Pdf
                <VisuallyHiddenInput
                  type="file"
                  onChange={handleOnChange}
                  accept=".pdf"
                />
              </Button>
            </Grid>
            <Grid item xs>
              <Button
                disabled={!anyXrSupported}
                component="label"
                variant="contained"
              >
                {arSupported
                  ? "Engage AR"
                  : vrSupported
                    ? "Engage VR"
                    : "XR Not Supported"}
                <VisuallyHiddenXRButton
                  mode={xrMode}
                  sessionInit={{
                    optionalFeatures: [
                      "local",
                      "local-floor",
                      "bounded-floor",
                      "hand-tracking",
                      "layers",
                    ],
                  }}
                />
              </Button>
            </Grid>
            <Grid item xs={6} md={6}>
              <Canvas
                id="rrt_star_canvas"
                style={{
                  minHeight: "300px",
                  height: "70vh",
                  marginTop: "15px",
                  marginBottom: "15px",
                }}
              >
                <OrbitControls />
                <WalkingXR pdfRef={pdfRef} />
              </Canvas>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
