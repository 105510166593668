import { Container, Typography, Toolbar } from "@mui/material";
import React from "react";
import useMediaQuery from '@mui/material/useMediaQuery';
import WalkingTest from "./routes/walking_test";
import { ThemeProvider, createTheme, useTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import IconButton from '@mui/material/IconButton';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';

const ColorModeContext = React.createContext({ toggleColorMode: () => {} });

function InnerApp() {
  const theme = useTheme();
  const colorMode = React.useContext(ColorModeContext);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container maxWidth="xl">
        <Toolbar sx={{ borderBottom: 1, borderColor: "divider" }} title={"I can't read without my goggles."}>
          <Typography
            component="h1"
            variant="h2"
            color="inherit"
            align="center"
            noWrap
            sx={{ flex: 1 }}
          >
            Reading Goggles 🥽
          </Typography>
      <IconButton sx={{ ml: 1 }} onClick={colorMode.toggleColorMode} color="inherit">
        {theme.palette.mode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
      </IconButton>
        </Toolbar>
        <main>
          <WalkingTest />
        </main>
      </Container>
    </ThemeProvider>
  );
}

const App = () => {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const [mode, setMode] = React.useState<'light' | 'dark'>(prefersDarkMode ? 'dark' : 'light');
  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
      },
    }),
    [],
  );

  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode,
        },
      }),
    [mode],
  );

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <InnerApp />
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}


export default App;
